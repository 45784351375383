import { graphql } from "gatsby";
import React from "react";
import Image from "../components/Image";
import PatternBackground from "../components/PatternBackground";
import { months } from "../lib/helpers";
import BlockContent from "../components/BlockContent";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import CookieBanner from "../components/CookieBanner";
import Cta from "../components/Cta";

const Article = ({
  data: {
    sanityArticle: { title, author, mainImage, body, publishedAt, buttonText },
  },
}) => {
  let date = new Date(publishedAt);
  date = `${date.getDay()}. ${months[date.getMonth()]} ${date.getFullYear()}`;
  return (
    <>
      <Seo title={title} ogImage={mainImage} />
      <Nav />
      <article className="md:px-page px-8">
        <div className="grid lg:grid-cols-2 gap-x-24 mt-16 items-center">
          <PatternBackground className="md:p-16">
            <Image
              image={mainImage}
              className="rounded-ryddi-lg"
              aspectRatio={10 / 16}
              alt={title}
            />
          </PatternBackground>
          <div>
            <h1 className="text-heading-4 md:text-heading-2 my-4">{title}</h1>
            <p className="text-subheading-2">Skrevet av {author.name}</p>
            <p className="mt-2">Publisert {date}</p>
          </div>
        </div>
        <div className="max-w-screen-md mx-auto mt-16">
          <BlockContent blocks={body} prose />
          {buttonText && (
            <Cta
              cta={{
                text: buttonText,
                internal: { _type: "register" },
              }}
            />
          )}
        </div>
      </article>
      <Footer />
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query ArticleQuery($id: String) {
    sanityArticle(_id: { eq: $id }) {
      title
      author: _rawAuthor(resolveReferences: { maxDepth: 2 })
      publishedAt
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 2000)
        }
      }
      body: _rawBody(resolveReferences: { maxDepth: 4 })
      buttonText
    }
  }
`;

export default Article;
